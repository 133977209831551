import React, { FunctionComponent } from 'react';
// import { useTranslation } from 'react-i18next';
// import { useTheme } from '@mui/material/styles';

import Logo from '../../images/logo_nutriportal2024.png';

import {
  Avatar,
  Box,
} from '@mui/material';

import {
  Typography
} from '../../components/v2/styled';

import { Practice } from '../../models/Practice';

type Props = {
  practice: Practice;
  showName?: boolean;
  showUrl?: boolean;
};

export const PracticeLogoAvatar: FunctionComponent<Props> = ({practice, showName, showUrl}) => {
  // const theme = useTheme();
  // const {t, i18n} = useTranslation(['translations']);

  return (<Box sx={{
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    flex: 1
  }}>
    <Avatar sx={{
      height: 120,
      width: 120,
      borderRadius: 0,
      ...(practice.item_is_square ? {} : {
        width: 200,
        '& > img': {
          objectFit: 'contain'
        }
      })
    }} src={practice.get_medium || Logo}></Avatar>
    {showName && <Typography variant="subtitle1" sx={{
      marginTop: 1.5,
      fontSize: "1.3rem",
      fontWeight: 'bold'
    }}>{practice.name}</Typography>}
    {showUrl && !!practice.url_path && <Typography variant="subtitle1" sx={{
      marginTop: 1.5
    }}>https://{practice.url_path}.nutriportal.eu/</Typography>}
  </Box>);
}