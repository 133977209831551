import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StarBorderPurple500 from '@mui/icons-material/StarBorderPurple500';

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
} from '@mui/material';

import {
  Button,
  Fab,
  IconButton,
  Typography
} from '../../../components/v2/styled';
import { CoachWeekLayoutEdit } from '../../../components/v2/dialogs/CoachWeekLayoutEdit';
import { Premium } from '../../../components/v2/dialogs/Premium';
import { SnackDestroyed } from '../../../components/v2/snacks/Destroyed';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { User } from '../../../models/User';
import { WeekLayout } from '../../../models/WeekLayout';

const { DateTime } = require("luxon");

type Props = {
  premiumLocked?: boolean;
}

export const CoachSettingsWeekLayout: FunctionComponent<Props> = ({premiumLocked}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [editLayoutOpen, setEditLayoutOpen] = useState<boolean>(false);
  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [activeLayout, setActiveLayout] = useState<WeekLayout>();
  const [activeId, setActiveId] = useState<number>(login?.id || 0);
  const [premiumOpen, setPremiumOpen] = useState<boolean>(false);

  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name'],
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              is_secretary: '0',
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: weekLayouts,
    isLoading: weekLayoutsLoading,
    isError: weekLayoutsError,
    isSuccess: weekLayoutsSuccess,
    refetch: weekLayoutsRefetch,
  } = useQuery({
    queryKey: ["week_layouts", activeId],
    queryFn: () =>
      fetch_all<WeekLayout>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'week_layout',
          fields: ['id', 'first_day', 'last_day', 'num_weeks', 'total_hours', 'total_appointments', 'default_weeks', 'user_id'],
          order: 'last_day IS NULL DESC, last_day DESC',
          filter: {
            search: '',
            advanced: {
              user_id: activeId
            }
          }
        },
        login
      ),
      // select: (d) => {
      //   return {
      //     ...d,
      //     first_day: DateTime.fromFormat(d.first_day || '', "y-LL-d")
      //   };
      // },
    enabled: !!activeId,
  });






  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<WeekLayout>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      setDestroyedOpen(true);
      weekLayoutsRefetch();
      return true;
    },
    onSuccess: (data, variables, context) => {
      setDestroyedOpen(true);
      weekLayoutsRefetch();
    },
  });
  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<WeekLayout>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      weekLayoutsRefetch();
      setActiveLayout(undefined);
      setEditLayoutOpen(false);
      setSavedOpen(true);
      // setLogin(data);
    },
  });








  return <Grid container spacing={2}>
    {!!premiumLocked && <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1201,
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      textAlign: 'center',
      cursor: 'pointer'
    }} onClick={() => {
      setPremiumOpen(true);
    }}>
      <StarBorderPurple500 sx={{marginTop: '20%', color: theme.palette.primary.main}} />
    </Box>}
    {login?.is_super && (coaches || []).length > 1 && <Grid item xs={12}>
      {(coaches || []).map(coach => <Button
        label={coach.full_name || ''}
        id={`coach_${coach.id}`}
        onClick={() => {
          setActiveId(coach.id || 0);
        }}
        contained={activeId === coach.id}
        sx={{
          marginTop: 0,
          marginRight: 1
        }}
      />)}
    </Grid>}
    {!!weekLayoutsLoading && <Grid item xs={12} sx={{textAlign: 'center'}}>
      <CircularProgress />
    </Grid>}
    {!!weekLayoutsSuccess && weekLayouts.length < 1 && <Box sx={{textAlign: 'center'}}>
      <Typography sx={{fontStyle: 'italic', fontSize: '0.7rem', color: theme.palette.secondary.main}}>{t("general.no_results_found")}</Typography> 
    </Box>}
    {!!weekLayoutsSuccess && weekLayouts.length > 0 && <>
      <Grid item xs={12} container spacing={2}>

        <Grid item xs={12}>
          <Box sx={{
            border: '1px solid transparent',
            borderColor: theme.palette.primary.main,
            padding: 1,
            borderRadius: 1
          }}>
            <Typography sx={{fontStyle: 'italic'}}>{t("coach.submenu.personal_calendar_settings.default_layout_description")}</Typography>
          </Box>
        </Grid>

        {weekLayouts.map(layout => <Grid item xs={12}>
          <Paper sx={{
            padding: 2,
            opacity: (!!layout.last_day && DateTime.fromISO(layout.last_day) < DateTime.now() ? 0.5 : 1)
          }}>
            <Typography variant={"subtitle2"}>{DateTime.fromISO(layout.first_day).toFormat("d LLL y")} - {!!layout.last_day ? DateTime.fromISO(layout.last_day).toFormat("d LLL y") : t("coach.submenu.personal_calendar_settings.indefinite", "zonder einddatum")}</Typography>
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={6} md={3} sx={{alignContent: 'end'}}>
                <Typography sx={{fontSize: '0.8rem'}}>{t("coach.models.default_week_layouts.num_weeks", "Aantal weken per iteratie")}</Typography>
                <Typography sx={{
                  paddingLeft: 2,
                  borderLeft: '2px solid transparent',
                  borderColor: theme.palette.primary.main,
                }}>{layout.num_weeks}</Typography>
              </Grid>
              <Grid item xs={6} md={3} sx={{alignContent: 'end'}}>
                <Typography sx={{fontSize: '0.8rem'}}>{t("coach.models.default_week_layouts.hours_total", "Totaal aantal uren")}</Typography>
                <Typography sx={{
                  paddingLeft: 2,
                  borderLeft: '2px solid transparent',
                  borderColor: theme.palette.primary.main,
                }}>{layout.total_hours}</Typography>
              </Grid>
              <Grid item xs={6} md={3} sx={{alignContent: 'end'}}>
                <Typography sx={{fontSize: '0.8rem'}}>{t("coach.models.default_week_layouts.appointment_count", "Afspraken tijdens deze periode")}</Typography>
                <Typography sx={{
                  paddingLeft: 2,
                  borderLeft: '2px solid transparent',
                  borderColor: theme.palette.primary.main,
                }}>{layout.total_appointments}</Typography>
              </Grid>
              <Grid item xs={6} md={3} sx={{alignContent: 'end', textAlign: 'right'}}>
                <IconButton title="Edit" color="default" onClick={() => {
                  setActiveLayout({
                    ...layout,
                    first_day: DateTime.fromFormat(layout.first_day, "y-LL-dd").toFormat("dd/LL/y"),
                    last_day: !!layout.last_day ? DateTime.fromFormat(layout.last_day, "y-LL-dd").toFormat("dd/LL/y") : undefined
                  });
                  setEditLayoutOpen(true);
                }}>
                  <EditIcon />
                </IconButton>
                <IconButton title="Remove" destroyBtn color="default" onClick={() => {
                  mutationDestroy.mutate({
                    formData: {
                      object: 'week_layout',
                      id: layout.id,
                      ob: {
                        id: layout.id,
                      }
                    }
                  });
                }}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>)}
      </Grid>
    </>}

    {!!activeLayout && <CoachWeekLayoutEdit
      onSave={() => {
        mutationSave.mutate({
          formData: {
            object: "week_layout",
            id: activeLayout?.id,
            ob: {
              id: activeLayout.id,
              practice_id: (login?.practice_id || currentPractice?.id),
              user_id: activeId,
              first_day: activeLayout.first_day,
              last_day: activeLayout.last_day,
              num_weeks: activeLayout.num_weeks
            },
            default_weeks: activeLayout.default_weeks,
            handler_id: login?.id
          }
        });
      }}
      open={editLayoutOpen}
      setOpen={(b) => {
        setActiveLayout(undefined);
        setEditLayoutOpen(b);
      }}
      weekLayout={activeLayout}
      setWeekLayout={setActiveLayout}
      isLoading={mutationSave.isPending}
    />}

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />

    <Premium
      open={premiumOpen}
      setOpen={(b) => {
        setPremiumOpen(b);
      }}
      mode="nutri_appointment"
    />

    <Fab
      onClick={() => {
        setActiveLayout({
          user_id: activeId,
          num_weeks: 1,
          default_weeks: [
            {
              week: 1
            }
          ]
        });
        setEditLayoutOpen(true);
      }}
    />
  </Grid>;
}

