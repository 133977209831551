import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';

import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';

import {
  Button,
  DatePicker,
  Editor,
  ImagePicker,
  MultiSelect,
  Page,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { SnackCopied } from '../../../components/v2/snacks/Copied';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { CoachSkill } from '../../../models/CoachSkill';
import { Country } from '../../../models/Country';
import { Practice } from '../../../models/Practice';
import { User } from '../../../models/User';

// import HelpersPractice from '../../../actions/helpers/practice';

const { DateTime } = require("luxon");

type Props = {}

export const AdminPracticeEdit: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  const queryClient = useQueryClient();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [mainObject, setMainObject] = useState<Practice>({
    name: '',
    coaches: [],
    coaches_active_not_secretary: [],
    locations: [],
    locations_active: [],
    locations_true_active: []
  });
  const [domainError, setDomainError] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  const [errors, setErrors] = useState<{[z:string]: boolean}>({});
  const [emailErrors, setEmailErrors] = useState<{[z:string]: boolean}>({});

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ['admin', "practice", objectId],
    queryFn: () =>
      fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice',
          id: objectId,
          fields: [
            'id', 'name', 'enabled', 'url_path', 'country_id', 'old_api_key', 'license_end_at', 'extra_data',
            'practice_type_ids', 'module_ids', 'coaches'
          ],
          sub_fields: {
            coaches: ['id', 'first_name', 'last_name', 'email', 'enabled', 'is_super', 'is_secretary', 'coach_skill_ids']
          },
        },
        login
      ),
    select: (d) => {
      return {
        ...d,
        license_end_at: DateTime.fromISO(d.license_end_at).toFormat("dd/LL/y"),
        // end_time: DateTime.fromISO(d.end_time).toFormat("dd/LL/y t"),
        // background_colour: d.background_colour || 'rgb(211, 211, 211)'
      };
    },
    enabled: !!login && parseInt(objectId || '', 10) > 0,
  });
  const {
    data: coachSkills,
    isLoading: coachSkillsLoading,
    isError: coachSkillsError,
    isSuccess: coachSkillsSuccess,
    // refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coach_skills"],
    queryFn: () =>
      fetch_all<CoachSkill>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach_skill',
          fields: ['id', 'name'],
          order: 'name ASC'
        },
        login
      ),
    enabled: !!login
  });
  const {
    data: countries,
    isLoading: countriesLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["countries", "select_list"],
    queryFn: () =>
      fetch_all<Country>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'country',
          fields: ['id', 'name', 'iso'],
          per_page: 100,
          page: 0,
          order: "name ASC"
        },
        login
      ),
    enabled: !!login,
  });
  const {
    data: daModules,
    isLoading: daModulesLoading,
    isError: daModulesError,
    isSuccess: daModulesSuccess,
    // refetch: daModulesRefetch,
  } = useQuery({
    queryKey: ["da_modules", "select_list"],
    queryFn: () =>
      fetch_all<{id: number; name: string;}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'da_module',
          fields: ['id', 'name'],
          per_page: 100,
          page: 0,
          order: "name ASC",
          filter: {
            advanced: {
              enabled: 1
            }
          }
        },
        login
      ),
    enabled: !!login,
  });
  const {
    data: practiceTypes,
    isLoading: practiceTypesLoading,
    isError: practiceTypesError,
    isSuccess: practiceTypesSuccess,
    // refetch: practiceTypesRefetch,
  } = useQuery({
    queryKey: ["practice_types", "select_list"],
    queryFn: () =>
      fetch_all<{id: number; name: string;}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice_type',
          fields: ['id', 'name'],
          per_page: 100,
          page: 0,
          order: "name ASC"
        },
        login
      ),
    enabled: !!login,
  });








  const mutationCheckCoachEmail = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{exists: boolean}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/check_email`,
        pars.formData,
        undefined
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsCheckEmailError(false);
      // setCheckEmailError(undefined);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsCheckEmailError(true);
      // setCheckEmailError(data as any);
      return true;
    },
    onSuccess: (data, variables, context) => {
      setEmailErrors({
        ...emailErrors,
        [variables.formData.email]: !!data.exists
      });
    },
  });
  const mutationCheckEmail = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/define_practice`,
        pars.formData,
        undefined
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsCheckEmailError(false);
      // setCheckEmailError(undefined);
      return true;
    },
    onError: (data, variables, context) => {
      setDomainError(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      setDomainError(true);
    },
    retry: false
  });
  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      queryClient.invalidateQueries({ queryKey: ["admin", "practices"] });
      if (!variables.keepOpen) navigate("/practices");
      if (!!variables.keepOpen && objectId === 'new') navigate(`/practice/${data.id}`);
      if (!!variables.keepOpen && objectId !== 'new') objectRefetch();
    },
  });







  const saveObject = (keep_open: boolean) => {
    mutationSave.mutate({
      formData: {
        object: 'practice',
        id: mainObject.id,
        ob: {
          id: mainObject.id,
          ...mainObject
        },
        fields: ['id']
      },
      keepOpen: keep_open
    });
  };

  let term = t('admin.models.practices.table_title');
  let termSingle = t("admin.models.practices.singular", "Praktijk");

  let page_title;
  if (typeof((object || {}).id) === 'undefined') {
    page_title = (mainObject || {}).name || termSingle.toLowerCase();
  } else {
    page_title = t("general.titles.edit", "Wijzig") + " " + (object || {}).name;
  }

  useEffect(() => {
    if (params.id !== 'new') objectRefetch();
  }, [params.id]);
  useEffect(() => {
    if (!!objectSuccess) setMainObject(object);
  }, [object]);
  useEffect(() => {
    let err:any = {};
    if (!mainObject.name) err.name = true;
    if (!mainObject.url_path) err.url_path = true;
    if (!!domainError) err.url_path = true;
    if (!mainObject.license_end_at) err.license_end_at = true;
    if ((mainObject?.coaches || []).length > 0) {
      (mainObject?.coaches || []).forEach(coach => {
        if (!err.coach && (!coach.first_name || !coach.last_name || !coach.email)) err.coach = true;
      });
    }
    
    setErrors(err);
  }, [mainObject]);
  useEffect(() => {
    let to = setTimeout(() => {
      if (!!mainObject && !!mainObject.url_path) {
        mutationCheckEmail.mutate({formData: {
          subdomain: mainObject.url_path,
          check_id: mainObject?.id,
          fields: ['id']
        }});
      }
    }, 2000);

    return () => {
      clearTimeout(to);
    }
  }, [!!mainObject, mainObject?.url_path]);

  return <Page
    title={page_title}
    titleCrumbs={[
      {
        title: term,
        path: "/practices"
      }
    ]}
  >
    <Paper sx={{padding: 2}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("practices.headings.general")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            id="name"
            caption={t("admin.models.practices.name", "Name")}
            starred
            value={mainObject.name || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                name: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            fullWidth
            displayEmpty
            backend
            caption={t("coach.models.users.country_id", "Land")}
            id="country"
            value={mainObject.country_id || ''}
            setValue={(v) => {
              setMainObject({
                ...mainObject,
                country_id: !!v ? parseInt(v.toString(), 10) : undefined
              });
            }}
          >
            <MenuItem value="">--- {t("shared.signup.field.country")} ---</MenuItem>
            {(countries || []).map((country) => (<MenuItem value={country.id}>{country.name}</MenuItem>))}
          </Select>
        </Grid>
        {mainObject.old_api_key && <Grid item xs={12}>
          <Typography variant="caption">API Key</Typography><br />
          <Typography>{mainObject.old_api_key}</Typography>
        </Grid>}
        <Grid item xs={12} md={6}>
          <TextInput
            id="url_path"
            error={!!domainError ? 'Already exists' : undefined}
            caption={t("admin.models.practices.url_path", "Name")}
            endIcon={<ContentCopyIcon color="primary" sx={{
              cursor: 'pointer'
            }} onClick={(e) => {
              e.preventDefault();
              navigator.clipboard.writeText(`https://${mainObject.url_path}.nutriportal.eu/`);
              setOpen(true);
            }} />}
            starred
            value={mainObject.url_path || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                url_path: e
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Switcher
            id="enabled"
            caption={t("admin.models.practices.enabled")}
            value={!!mainObject?.enabled}
            onChange={(c) => {
              setMainObject({
                ...mainObject,
                enabled: c
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography sx={{
            fontSize: '0.7rem'
          }}>{t("admin.models.practices.license_end_at")} *</Typography>
          <DatePicker
            openTo="day"
            label=""
            format={"dd/LL/y"}
            // caption={t("client.appointment.option_start_date", "Welke dag draagt je voorkeur?")}
            views={["year", "month", "day"]}
            setValue={(e) => {
              setMainObject({
                ...mainObject,
                license_end_at: e
              });
            }}
            value={mainObject.license_end_at || ''}
            noIcon
            id={`license_end_at1`}
            sx={{
              box: {
                width: 'auto',
                display: 'inline-block',
                marginRight: 0.5
              },
              field_box: {
                "svg": {
                  display: 'none'
                }
              },
              field: {
                width: 'auto',
                marginTop: 0,
                "input": {
                  textAlign: 'center'
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Switcher
            id="is_nutri"
            caption={t("admin.models.practices.is_nutri")}
            value={!!mainObject?.extra_data?.is_nutri}
            onChange={(c) => {
              setMainObject({
                ...mainObject,
                extra_data: {
                  ...(mainObject.extra_data || {}),
                  is_nutri: !!c
                }
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {!!mainObject?.extra_data?.is_nutri && <TextInput
            id="fm_filename"
            caption={t("admin.models.practices.fm_filename")}
            value={mainObject?.extra_data?.fm_filename || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                extra_data: {
                  ...(mainObject.extra_data || {}),
                  fm_filename: e
                }
              });
            }}
          />}
        </Grid>
        <Grid item xs={12} md={3}>
          <Switcher
            id="is_sms_enabled"
            caption={t("admin.models.practices.is_sms_enabled")}
            value={!!mainObject?.extra_data?.is_sms_enabled}
            onChange={(c) => {
              setMainObject({
                ...mainObject,
                extra_data: {
                  ...(mainObject.extra_data || {}),
                  is_sms_enabled: !!c
                }
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">{t("coach.models.locations.practice_type", "Locatietype")}</Typography><br />
          {(practiceTypes || []).map(practiceType => <Chip
            sx={{marginRight: 1, marginBottom: 1, cursor: 'pointer'}}
            label={practiceType.name}
            onClick={(e) => {
              if ((mainObject?.practice_type_ids || []).indexOf(practiceType.id) > -1) {
                setMainObject({
                  ...mainObject,
                  practice_type_ids: (mainObject?.practice_type_ids || []).filter(fff => fff !== practiceType.id)
                });
              } else {
                setMainObject({
                  ...mainObject,
                  practice_type_ids: [...(mainObject?.practice_type_ids || []), practiceType.id]
                });
              }
            }}
            variant="outlined"
            icon={(mainObject?.practice_type_ids || []).filter(fff => fff === practiceType.id).length > 0 ? <DoneIcon /> : <AddIcon />}
            color={(mainObject?.practice_type_ids || []).filter(fff => fff === practiceType.id).length > 0 ? "primary" : 'default'}
          />)}
        </Grid>
        





        <Grid item xs={12} sx={{marginTop: 5}}>
          <Typography variant="h5">{t("practices.headings.modules")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">{t("coach.models.locations.modules", "Modules")} <span style={{color: theme.palette.primary.main, cursor: 'pointer'}} onClick={() => {
            if ((mainObject?.module_ids || []).length > 0) {
              setMainObject({
                ...mainObject,
                module_ids: []
              });
            } else {
              setMainObject({
                ...mainObject,
                module_ids: (daModules || []).map(mm => mm.id)
              });
            }
          }}>(de)select all</span></Typography><br />
          {(daModules || []).map(daModule => <Chip
            sx={{marginRight: 1, marginBottom: 1, cursor: 'pointer'}}
            label={daModule.name}
            onClick={(e) => {
              if ((mainObject?.module_ids || []).indexOf(daModule.id) > -1) {
                setMainObject({
                  ...mainObject,
                  module_ids: (mainObject?.module_ids || []).filter(fff => fff !== daModule.id)
                });
              } else {
                setMainObject({
                  ...mainObject,
                  module_ids: [...(mainObject?.module_ids || []), daModule.id]
                });
              }
            }}
            variant="outlined"
            icon={(mainObject?.module_ids || []).filter(fff => fff === daModule.id).length > 0 ? <DoneIcon /> : <AddIcon />}
            color={(mainObject?.module_ids || []).filter(fff => fff === daModule.id).length > 0 ? "primary" : 'default'}
          />)}
        </Grid>





        <Grid item xs={12} sx={{marginTop: 5}}>
          <Typography variant="h5">{t("practices.headings.coaches")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Box sx={{
              marginBottom: 3,
              '& .Mui-selected': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)'
              }
            }}>
              <Tabs
                value={currentTab}
                onChange={(e, v) => {setCurrentTab(v);}}
                indicatorColor="primary"
                textColor="primary"
                aria-label="Filters"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                {(mainObject?.coaches || []).map((coach, i) => <Tab icon={!!coach.id ? <PersonIcon color={(!coach.first_name || !coach.last_name || !coach.email) ? "secondary" : "primary"} /> : <PersonAddIcon color={(!coach.first_name || !coach.last_name || !coach.email) ? "secondary" : "primary"} />} label={coach.first_name} value={i} />)}
                <Tab onClick={(e) => {
                  e.preventDefault();
                  setMainObject({
                    ...mainObject,
                    coaches: [
                      ...(mainObject?.coaches),
                      {
                        first_name: `user${(mainObject?.coaches).length + 1}`,
                        last_name: '',
                        email: ''
                      } as User
                    ]
                  });
                  setCurrentTab((mainObject?.coaches).length);
                }} icon={<AddIcon />} label={t("client.general.actions.add")} />
              </Tabs>
              <Divider sx={{borderColor: theme.palette.primary.main}} />
            </Box>

            {(mainObject?.coaches || []).map((coach, i) => currentTab !== i ? <></> : <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextInput
                  id="coach_first_name"
                  caption={t("admin.models.coaches.first_name")}
                  starred
                  value={coach.first_name || ''}
                  onChange={(e) => {
                    let tmp = (mainObject?.coaches || [])[i];
                    tmp.first_name = e;
                    let newCoaches = [...(mainObject?.coaches || [])];
                    newCoaches[i] = tmp;

                    setMainObject({
                      ...mainObject,
                      coaches: newCoaches
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  id="coach_last_name"
                  caption={t("admin.models.coaches.last_name")}
                  starred
                  value={coach.last_name || ''}
                  onChange={(e) => {
                    let tmp = (mainObject?.coaches || [])[i];
                    tmp.last_name = e;
                    let newCoaches = [...(mainObject?.coaches || [])];
                    newCoaches[i] = tmp;

                    setMainObject({
                      ...mainObject,
                      coaches: newCoaches
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="coach_email"
                  caption={t("admin.models.coaches.email")}
                  starred
                  value={coach.email || ''}
                  onChange={(e) => {
                    let tmp = (mainObject?.coaches || [])[i];
                    tmp.email = e;
                    let newCoaches = [...(mainObject?.coaches || [])];
                    newCoaches[i] = tmp;

                    mutationCheckCoachEmail.mutate({formData: {
                      email: e,
                      skip: tmp?.id
                    }});

                    setMainObject({
                      ...mainObject,
                      coaches: newCoaches
                    });
                  }}
                />
                {!!emailErrors[coach.email || ''] && <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem', color: theme.palette.secondary.main}}>This email aldready exists</Typography>}
              </Grid>
              <Grid item xs={12} md={3}>
                <Switcher
                  id="coach_enabled"
                  caption={t("admin.models.coaches.enabled")}
                  value={!!coach?.enabled}
                  onChange={(c) => {
                    let tmp = (mainObject?.coaches || [])[i];
                    tmp.enabled = !!c;
                    let newCoaches = [...(mainObject?.coaches || [])];
                    newCoaches[i] = tmp;

                    setMainObject({
                      ...mainObject,
                      coaches: newCoaches
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Switcher
                  id="coach_is_secretary"
                  caption={t("admin.models.coaches.is_secretary")}
                  value={!!coach?.is_secretary}
                  onChange={(c) => {
                    let tmp = (mainObject?.coaches || [])[i];
                    tmp.is_secretary = !!c;
                    let newCoaches = [...(mainObject?.coaches || [])];
                    newCoaches[i] = tmp;

                    setMainObject({
                      ...mainObject,
                      coaches: newCoaches
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Switcher
                  id="coach_is_super"
                  caption={t("admin.models.coaches.is_super")}
                  value={!!coach?.is_super}
                  onChange={(c) => {
                    let tmp = (mainObject?.coaches || [])[i];
                    tmp.is_super = !!c;
                    let newCoaches = [...(mainObject?.coaches || [])];
                    newCoaches[i] = tmp;

                    setMainObject({
                      ...mainObject,
                      coaches: newCoaches
                    });
                  }}
                />
              </Grid>
              {!!coach.id && <Grid item xs={12} md={3}>
                <Button
                  onClick={() => {
                    navigate("/user_page", {state: {email: coach.email}});
                  }}
                  label="Impersonate page"
                  id="imp_link"
                  contained
                  sx={{
                    marginTop: 1
                  }}
                />
              </Grid>}
              <Grid item xs={12}>
                <Typography sx={{fontSize: '0.7rem'}}>{t("coach.submenu.account_settings.skills")}:</Typography>
                {(coachSkills || []).map(skill => <Chip
                  sx={{marginRight: 1, marginBottom: 1, cursor: 'pointer'}}
                  label={skill.name}
                  onClick={(e) => {
                    let tmp = (mainObject?.coaches || [])[i];
                    if ((coach.coach_skill_ids || []).indexOf(skill.id) > -1) {
                      tmp.coach_skill_ids = (tmp.coach_skill_ids || []).filter(fff => fff !== skill.id);
                    } else {
                      tmp.coach_skill_ids = [...(tmp.coach_skill_ids || []), skill.id];
                    }
                    let newCoaches = [...(mainObject?.coaches || [])];
                    newCoaches[i] = tmp;

                    setMainObject({
                      ...mainObject,
                      coaches: newCoaches
                    });
                  }}
                  variant="outlined"
                  icon={(coach.coach_skill_ids || []).filter(fff => fff === skill.id).length > 0 ? <DoneIcon /> : <AddIcon />}
                  color={(coach.coach_skill_ids || []).filter(fff => fff === skill.id).length > 0 ? "primary" : 'default'}
                />)}
              </Grid>
            </Grid>)}
          </Box>
        </Grid>


        







        <Grid item xs={12} sx={{
          alignContent: 'baseline',
          textAlign: 'center',
          position: 'sticky',
          bottom: '-24px',
          backgroundColor: 'white',
          zIndex: 1201
        }}>
          <Divider sx={{marginTop: 1, marginBottom: 0}} />

          {!!mutationSave.isPending && <CircularProgress />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save")}
            id='save'
            disabled={Object.keys(errors).length > 0}
            contained
            onClick={(v) => {
              saveObject(false);
            }}
            sx={{marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save_keep_open")}
            id='save_opened'
            disabled={Object.keys(errors).length > 0}
            contained
            onClick={(v) => {
              saveObject(true);
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.cancel")}
            id='cancel'
            text
            // contained
            onClick={(v) => {
              navigate("/practices");
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
        </Grid>
      </Grid>
    </Paper>
    
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
    <SnackCopied open={open} setOpen={setOpen} />
  </Page>;
}
